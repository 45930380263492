import React from 'react'

const Coin = ({name, image, symbol, value, volume, change}) => {
  return (
    <div className='coin-card'>
        <div className='coin-holder'>
            <img src={image} alt={name} className='coin-icon' />
            <p className='coin-name'>{name}</p>
        </div>
        <p className='coin-symbol'>{symbol}</p>
        <p className='coin-value'>{value.toLocaleString()} $</p>
        <p className='coin-volume'>{volume.toLocaleString()} $</p>
        {change < 0 ? <p className='coin-change red'>{change} %</p> : <p className='coin-change green'>{change} %</p>}
    </div>
  )
}

export default Coin