import React, { useState, useEffect} from 'react';
import axios from 'axios';
import Coin from './components/Coin';


function App() {

  const [coins, setCoins] = useState([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)

  useEffect(() => {
    axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=${page}&sparkline=false`)
    .then((response) => {
      setCoins(response.data)
    })
    .catch((error) => console.log(error))
  }, [page])

  const handleSearch = (word) => {
    setSearch(word.target.value);
  }
  
  const NextPage = () => {
    setPage(page + 1)
  }

  const PreviousPage = () => {
    if (page === 1) {
      return
    }

    setPage(page - 1)
  }

  const filteredCoins = coins.filter((coin) => coin.name.toLowerCase().includes(search.toLowerCase()))

  return (

    <div className="coin-search-container">
      <h1 className="coin-search-title">Search a Cryptocurrency</h1>
      <form className="form-search">
        <input type="text" className="form-search" placeholder="Enter Cryptocurrency" onChange={handleSearch}></input>
      </form>
      <div className="label-container">
        <div className="label-name">Name</div>
        <div className="label-symbol">Currency</div>
        <div className="label-price">Price</div>
        <div className="label-volume">Volume</div>
        <div className="label-percent">Daily Change</div>
      </div>
      {filteredCoins.map((coin) => {
        return (<Coin
          key={coin.id}
          name={coin.name}
          value={coin.current_price}
          image={coin.image}
          symbol={coin.symbol.toUpperCase()}
          volume={coin.total_volume}
          change={coin.price_change_percentage_24h} />)
      })}
      <div className="buttonholder">
        <button onClick={PreviousPage} className='button-29'>Previous Page</button>
        <button onClick={NextPage} className='button-29'>Next Page</button>
      </div>
    </div>

  );
}

export default App;
